<template>
  <base-info-card title="Manfaat">
    <a
      v-for="feature in features"
      :key="feature"
      :text="feature"
      class="mb-2 grey--text body-1 d-block text-none"
      href="#"
      v-html="`&rsaquo; ${feature}`"
    />
  </base-info-card>
</template>

<script>
  export default {
    name: 'SectionFeaturesAlt',

    data: () => ({
      features: [
        'Digital Proses',
        '24/7 Customer Service',
      ],
    }),
  }
</script>
